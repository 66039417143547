import { Module } from "./types";

import userModule from "./modules/user/UserModule";
import quizModule from "./modules/quiz/QuizModule";
import lectorModule from "./modules/lector/LectorModule";
import videoModule from "./modules/video/VideoModule";
import videoManagerModule from "./modules/video-manager/VideoManagerModule";
import studyMaterialModule from "./modules/study-material/StudyMaterialModule";
import adminModule from "./modules/admin/AdminModule";
import demoModule from "./modules/demo/DemoModule";

export const modules: Array<Module> = [
  userModule,
  quizModule,
  lectorModule,
  videoModule,
  videoManagerModule,
  studyMaterialModule,
  adminModule,
];

export const publicModules: Array<Pick<Module, "route" | "name">> = [demoModule];
