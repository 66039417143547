import { Duration } from "moment";
import slugify from "slugify";
import { UserContentTree, UserView } from "../types";
import { CategoryWithTopics, CourseRegistration, StudyGroup, TractType, User } from "@namedicinu/internal-types";

export { guid, guidFrom } from "@namedicinu/internal-types";

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const bufferToHex = (buffer: ArrayBuffer) => {
  return [...new Uint8Array(buffer)].map((b) => b.toString(16).padStart(2, "0")).join("");
};

export const setEquals = (a: Iterable<any>, b: Iterable<any>) => {
  const setA = new Set(a);
  const setB = new Set(b);
  if (setA.size !== setB.size) return false;
  for (const a of setA) {
    if (!setB.has(a)) return false;
  }
  return true;
};

export const slug = (message: string): string =>
  slugify(message, {
    remove: /[.?]/g,
  }).toLowerCase();

export const splitAsHostPort = (host: string): [string, number] => {
  if (host.startsWith("http://") || host.startsWith("https://")) {
    host = host.split("://")[1]!;
  }
  const separatorIndex = host.lastIndexOf(":"); // Finding the last since IPv6 addr also has colons.
  if (separatorIndex <= 0 || separatorIndex + 1 === host.length) {
    throw new Error(`Invalid host ${host} with no separate hostname and port!`);
  }
  const port = parseInt(host.substring(separatorIndex + 1), 10);
  if (host[0] === "[") {
    // Bracket-quoted `[ipv6addr]:port` => return "ipv6addr" (without brackets).
    return [host.substring(1, separatorIndex - 1), port];
  } else {
    return [host.substring(0, separatorIndex), port];
  }
};

export const formatDuration = (value: Duration): string => {
  return [
    value.hours() > 0 && `${value.hours()}:`,
    value.minutes() < 10 && "0",
    value.minutes(),
    ":",
    value.seconds() < 10 && "0",
    value.seconds(),
  ]
    .filter(Boolean)
    .join("");
};

export const confirm = (message = "Are you sure?"): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (window.confirm(message)) {
      resolve();
    } else {
      reject("User cancelled the operation.");
    }
  });
};

export const buildUserContentTree = (
  user: User,
  content: Array<CategoryWithTopics>,
  tract: TractType,
  courseRegistrationId?: string,
): UserContentTree => {
  const tree: UserContentTree = { categories: [] };

  for (const category of content) {
    const topics: Array<{ topicId: string; title: string }> = [];
    for (const topic of category.topics) {
      if (user.hasContentAccess(category.categoryId, topic.topicId, tract, courseRegistrationId)) {
        topics.push({ topicId: topic.topicId, title: topic.title });
      }
    }
    if (topics.length > 0) {
      tree.categories.push({ categoryId: category.categoryId, title: category.title, topics });
    }
  }

  return tree;
};

export const buildUserViews = (
  user: User | undefined,
  courseRegistrations: Array<CourseRegistration>,
  content: Array<CategoryWithTopics>,
  studyGroups: Array<StudyGroup>,
): UserView[] => {
  const views: UserView[] = [];

  if (user) {
    if (user.isAdmin()) {
      views.push({
        role: "admin",
        modules: {
          quiz: buildUserContentTree(user, content, "quiz"),
          lector: true,
          video: buildUserContentTree(user, content, "video"),
          videoManager: true,
          studyMaterial: { studyGroups },
          admin: true,
        },
      });
    }

    if (user.isLector()) {
      views.push({
        role: "lector",
        modules: {
          quiz: buildUserContentTree(user, content, "quiz"),
          lector: true,
          video: buildUserContentTree(user, content, "video"),
          videoManager: false,
          studyMaterial: { studyGroups },
          admin: false,
        },
      });
    }

    if (user.isContentManager()) {
      views.push({
        role: "content-manager",
        modules: {
          quiz: false,
          lector: false,
          video: buildUserContentTree(user, content, "video"),
          videoManager: true,
          studyMaterial: false,
          admin: false,
        },
      });
    }

    for (const courseRegistration of courseRegistrations) {
      const studyGroupIds = user.activeContentAccess
        .filter((ca) => ca.courseRegistrationId === courseRegistration.courseRegistrationId && ca.studyGroupId)
        .map((ca) => ca.studyGroupId);
      const registrationStudyGroups = studyGroups.filter((sg) => studyGroupIds.includes(sg.studyGroupId));

      views.push({
        courseRegistration: courseRegistration,
        modules: {
          quiz: buildUserContentTree(user, content, "quiz", courseRegistration.courseRegistrationId),
          lector: false,
          video: buildUserContentTree(user, content, "video", courseRegistration.courseRegistrationId),
          videoManager: false,
          studyMaterial: registrationStudyGroups ? { studyGroups: registrationStudyGroups } : false,
          admin: false,
        },
      });
    }
  }

  if (views.length === 0) {
    views.push({
      role: "user",
      modules: {
        quiz: false,
        lector: false,
        video: false,
        videoManager: false,
        studyMaterial: false,
        admin: false,
      },
    });
  }

  return views;
};

export const userViewId = (userView: UserView) =>
  userView.role || userView.courseRegistration?.courseRegistrationId || "";
