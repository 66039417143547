import { lazy } from "react";
import { FormattedMessage } from "react-intl";
import { CheckSquare } from "react-feather";

import QuizWrapper from "./components/QuizWrapper";
import { Module } from "../../types";

const QuizPage = lazy(() => import("./pages/QuizPage"));


export default {
  route: {
    path: "/quiz",
    element: <QuizWrapper />,
    children: [
      {
        path: "/quiz",
        index: true,
        element: <QuizPage />,
      },
      {
        path: "/quiz/:categoryId",
        element: <QuizPage />,
      },
      {
        path: "/quiz/:categoryId/:modeId",
        element: <QuizPage />,
      }
    ]
  },
  allowed: (userView) => !!userView.modules.quiz,
  name: <FormattedMessage id="module.quiz.name" defaultMessage="Quiz" />,
  icon: <CheckSquare />,
} satisfies Module;
