import {
  ValidationOptions,
  ValidationArguments,
  buildMessage,
  ValidateBy,
  isString,
  isBoolean,
  isInt,
} from "class-validator";
import type { ValidationMetadata } from "class-validator/types/metadata/ValidationMetadata";
import { Container, Token } from "typedi";
import type { SchemaObject } from "openapi3-ts";
import * as yup from "yup";

import { AssetType, WebEntryType } from "../types";
import { isLocalized, locales } from "@namedicinu/internal-types";

export interface AssetValidatorService {
  validateAsset(assetId: string, type: AssetType): boolean;
}
export const AssetValidatorServiceToken = new Token<AssetValidatorService>("assetValidatorService");

export interface ReferenceValidatorService {
  validateReference(webEntryId: string, type: WebEntryType): boolean;
}
export const ReferenceValidatorServiceToken = new Token<ReferenceValidatorService>("referenceValidatorService");

export function IsAsset(type: AssetType, validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isAsset",
      constraints: [type],
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [_type] = args.constraints;
          try {
            const assetType = _type as AssetType;
            if (!isString(value)) return false;
            if (Container.has(AssetValidatorServiceToken)) {
              const validatorService: AssetValidatorService = Container.get(AssetValidatorServiceToken);
              return validatorService.validateAsset(value, assetType);
            }
            return true;
          } catch (e) {
            console.error(e);
            return false;
          }
        },
        defaultMessage: buildMessage((eachPrefix) => eachPrefix + "$property must be a valid asset", validationOptions),
      },
    },
    validationOptions,
  );
}

export function IsReference(type: WebEntryType, validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isReference",
      constraints: [type],
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [_type] = args.constraints;
          try {
            const webEntryType = _type as WebEntryType;
            if (!isString(value)) return false;
            if (Container.has(ReferenceValidatorServiceToken)) {
              const validatorService: ReferenceValidatorService = Container.get(ReferenceValidatorServiceToken);
              return validatorService.validateReference(value, webEntryType);
            }
            return true;
          } catch (e) {
            console.error(e);
            return false;
          }
        },
        defaultMessage: buildMessage((eachPrefix) => eachPrefix + "$property must be a valid asset", validationOptions),
      },
    },
    validationOptions,
  );
}

export function IsMarkdown(validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isMarkdown",
      validator: {
        validate: isString,
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + "$property must be a markdown string",
          validationOptions,
        ),
      },
    },
    validationOptions,
  );
}

export function IsLocalizedString(validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isLocalizedString",
      validator: {
        validate: (value, args) => {
          return isLocalized(value, args) && value.getValues().every((v) => isString(v));
        },
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + "$property must be an Localized instance with string values",
          validationOptions,
        ),
      },
    },
    validationOptions,
  );
}

export function IsLocalizedBoolean(validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isLocalizedBoolean",
      validator: {
        validate: (value, args) => {
          return isLocalized(value, args) && value.getValues().every((v) => isBoolean(v));
        },
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + "$property must be an Localized instance with boolean values",
          validationOptions,
        ),
      },
    },
    validationOptions,
  );
}

export function IsLocalizedInt(validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isLocalizedInt",
      validator: {
        validate: (value, args) => {
          return isLocalized(value, args) && value.getValues().every((v) => isInt(v));
        },
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + "$property must be an Localized instance with integer values",
          validationOptions,
        ),
      },
    },
    validationOptions,
  );
}

export function IsLocalizedMarkdown(validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isLocalizedMarkdown",
      validator: {
        validate: (value, args) => {
          return isLocalized(value, args) && value.getValues().every((v) => isString(v));
        },
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + "$property must be an Localized instance with markdown string values",
          validationOptions,
        ),
      },
    },
    validationOptions,
  );
}

export function IsLocalizedStringArray(validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isLocalizedStringArray",
      validator: {
        validate: (value, args) => {
          return (
            isLocalized(value, args) &&
            value.getValues().every((v) => Array.isArray(v) && v.every((vv) => isString(vv)))
          );
        },
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + "$property must be an Localized instance with array of string values",
          validationOptions,
        ),
      },
    },
    validationOptions,
  );
}

const infoPanelSchema = yup.array().of(
  yup
    .object()
    .shape({
      category: yup.string().required(),
      key: yup.string().nullable(),
      value: yup.string().nullable(),
    })
    .required(),
);
export function IsLocalizedInfoPanel(validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isLocalizedInfoPanel",
      validator: {
        validate: (value, args) => {
          return (
            isLocalized(value, args) &&
            value.getValues().every((v) => {
              try {
                infoPanelSchema.validateSync(v, { strict: true });
              } catch {
                return false;
              }
              return true;
            })
          );
        },
        defaultMessage: buildMessage(
          (eachPrefix) =>
            eachPrefix + "$property must be an Localized instance with array of objects with category and value",
          validationOptions,
        ),
      },
    },
    validationOptions,
  );
}

const fileSchema = yup
  .object()
  .shape({
    fileId: yup.string().required(),
    fileSize: yup.number().required(),
    fileName: yup.string().required(),
    contentType: yup.string().required(),
  })
  .required();
export function IsLocalizedFile(validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isLocalizedFile",
      validator: {
        validate: (value, args) => {
          return (
            isLocalized(value, args) &&
            value.getValues().every((v) => {
              try {
                fileSchema.validateSync(v, { strict: true });
              } catch {
                return false;
              }
              return true;
            })
          );
        },
        defaultMessage: buildMessage(
          (eachPrefix) =>
            eachPrefix + "$property must be an Localized instance with array of objects with category and value",
          validationOptions,
        ),
      },
    },
    validationOptions,
  );
}

export const additionalConverters = {
  isAsset: (meta: ValidationMetadata): SchemaObject => ({
    type: "string",
    format: `assetId:${meta.constraints[0]}`,
  }),
  isReference: (meta: ValidationMetadata): SchemaObject => ({
    type: "string",
    format: `referenceId:${meta.constraints[0]}`,
  }),
  isMarkdown: {
    type: "string",
    format: "markdown",
  } satisfies SchemaObject,
  isLocalizedString: {
    type: "object",
    properties: Object.fromEntries(locales.map((locale) => [locale, { type: "string" }])),
  } satisfies SchemaObject,
  isLocalizedBoolean: {
    type: "object",
    properties: Object.fromEntries(locales.map((locale) => [locale, { type: "boolean" }])),
  } satisfies SchemaObject,
  isLocalizedInt: {
    type: "object",
    properties: Object.fromEntries(locales.map((locale) => [locale, { type: "integer" }])),
  } satisfies SchemaObject,
  isLocalizedMarkdown: {
    type: "object",
    properties: Object.fromEntries(locales.map((locale) => [locale, { type: "string", format: "markdown" }])),
  } satisfies SchemaObject,
  isLocalizedStringArray: {
    type: "object",
    properties: Object.fromEntries(locales.map((locale) => [locale, { type: "array", items: { type: "string" } }])),
  } satisfies SchemaObject,
  isLocalizedInfoPanel: {
    type: "object",
    properties: Object.fromEntries(
      locales.map((locale) => [
        locale,
        {
          type: "array",
          items: {
            type: "object",
            properties: {
              category: { type: "string" },
              key: { type: "string" },
              value: { type: "string" },
            },
            required: ["category"],
          },
        },
      ]),
    ),
  } satisfies SchemaObject,
  isLocalizedFile: {
    type: "object",
    properties: Object.fromEntries(
      locales.map((locale) => [
        locale,
        {
          type: "object",
        },
      ]),
    ),
  } satisfies SchemaObject,
};
