import { FunctionComponent } from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { IntlProvider } from "react-intl";

import { modules, publicModules } from "./modules";
import IndexPage from "./pages/IndexPage";
import Layout from "./components/Layout";
import LoginPage from "./pages/LoginPage";
import SetupPage from "./pages/SetupPage";
import useLocale from "./hooks/useLocale";
import useUser from "./hooks/useUser";

import messagesSk from "./locale/sk.json";
import messagesCs from "./locale/cs.json";
import messagesEn from "./locale/en.json";

const locales = {
  sk: {
    messages: messagesSk
  },
  cs: {
    messages: messagesCs
  },
  en: {
    messages: messagesEn
  },
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        index: true,
        element: <IndexPage />,
      },
      ...modules.map((module) => module.route),
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/setup",
    element: <SetupPage />,
  },
  ...publicModules.map((module) => module.route),
]);

const App: FunctionComponent = () => {
  const user = useUser();
  const locale = useLocale();
  
  return (
    <IntlProvider
      locale={locale}
      timeZone={user.getTimezone()}
      messages={locales[locale].messages}
      onWarn={(message) => console.info(message)}
      onError={(error) => console.info(error.message)}
    >
      <RouterProvider router={router} />
    </IntlProvider>
  );
};

export default App;