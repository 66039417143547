import { FunctionComponent } from 'react';

import useApp from '../hooks/useApp';

const Topic: FunctionComponent<{
  categoryId: string;
  topicId: string;
}> = ({ categoryId, topicId }) => {
  const { content } = useApp();

  return (
    <>
      {content.find((category) => category.categoryId === categoryId)?.topics.find((topic) => topic.topicId === topicId)?.title || topicId}
    </>
  )
}

export default Topic;