import { Fragment, FunctionComponent, ReactNode } from "react";
import { useIntl } from "react-intl";

import Breadcrumb from "../../../components/Breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  TabContent,
  NavLink,
  TabPane,
} from "reactstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";

export type ManageWrapperType = {
  tabs: Array<{
    id: string;
    name: ReactNode;
    element?: ReactNode;
  }>,
};

const AdminWrapper: FunctionComponent<ManageWrapperType> = ({ tabs }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const activeTab = tabs.find((tab) => pathname.startsWith("/admin/"+tab.id));

  return (
    <>
      <Breadcrumb
        parent={intl.formatMessage({id: "module.admin.title", defaultMessage: "Admin"})}
        title={activeTab?.name}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="p-3">
                <Row>
                  <Col sm="2" className="tabs-responsive-side pt-3">
                    <Nav className="flex-column nav-pills border-tab-primary nav-left">
                      {tabs.map((tab) => (
                        tab.element ? (
                          <NavItem key={tab.id}>
                            <NavLink
                              tag="button"
                              className={clsx({"active": activeTab?.id == tab.id})}
                              onClick={() => navigate("/admin/"+tab.id, { replace: true })}
                            >
                              {tab.name}
                            </NavLink>
                          </NavItem>
                        ) : (
                          <Fragment key={tab.id}>
                            {tab.name}
                          </Fragment>
                        )
                      ))}
                    </Nav>
                  </Col>
                  <Col sm="10">
                    <TabContent>
                      <TabPane>
                        <Outlet />
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AdminWrapper;