import { lazy } from "react";
import { FormattedMessage } from "react-intl";
import { Users } from "react-feather";
import { RouteObject } from "react-router-dom";

import LectorWrapper from "./components/LectorWrapper";
import { Module } from "../../types";

const LectorIndexPage = lazy(() => import("./pages/LectorIndexPage"));
const LectorStudentsPage = lazy(() => import("./pages/LectorStudentsPage"));
const LectorStudentDetailPage = lazy(() => import("./pages/LectorStudentDetailPage"));
const LectorAreasPage = lazy(() => import("./pages/LectorAreasPage"));
const LectorQuestionsPage = lazy(() => import("./pages/LectorQuestionsPage"));
const LectorQuestionsSelectPage = lazy(() => import("./pages/LectorQuestionsSelectPage"));
const LectorQuestionPreviewPage = lazy(() => import("./pages/LectorQuestionPreviewPage"));
const LectorVideoWatchPage = lazy(() => import("./pages/LectorVideoWatchPage"));

const tabs = [
  {
    id: "index",
    name: <FormattedMessage id="module.lector.index" defaultMessage="Lector" />,
    element: <LectorIndexPage />,
  },
  {
    id: "students",
    name: <FormattedMessage id="module.lector.students" defaultMessage="Students" />,
    element: <LectorStudentsPage />,
  },
  {
    id: "areas",
    name: <FormattedMessage id="module.lector.areas" defaultMessage="Areas" />,
    element: <LectorAreasPage />,
  },
  {
    id: "questions",
    name: <FormattedMessage id="module.lector.questions" defaultMessage="Questions" />,
    element: <LectorQuestionsSelectPage />,
  },
  {
    id: "question-preview",
    name: <FormattedMessage id="module.lector.question-preview" defaultMessage="Question preview" />,
    element: <LectorQuestionPreviewPage />,
  },
  {
    id: "video-watch",
    name: <FormattedMessage id="module.lector.video-watch" defaultMessage="Video watch" />,
    element: <LectorVideoWatchPage />,
  },
];

export default {
  route: {
    path: "/lector",
    element: <LectorWrapper tabs={tabs} />,
    children: [
      ...tabs.map((tab) => (tab.element ? (
        {
          path: tab.id == "index" ? `/lector` : `/lector/${tab.id}`,
          element: tab.element,
          index: tab.id == "index",
        }
      ) : undefined)).filter(Boolean) as RouteObject[],
      {
        path: "/lector/questions/:categoryId/:topicId",
        element: <LectorQuestionsPage />,
      },
      {
        path: "/lector/questions/:categoryId/:topicId/:area",
        element: <LectorQuestionsPage />,
      },
      {
        path: "/lector/student/:email",
        element: <LectorStudentDetailPage />,
      },
    ]
  },
  allowed: (userView) => userView.modules.lector,
  name: <FormattedMessage id="module.lector.name" defaultMessage="Lector" />,
  icon: <Users />,
} satisfies Module;
