import { lazy } from "react";
import { FormattedMessage } from "react-intl";
import { Archive } from "react-feather";

import StudyMaterialWrapper from "./components/StudyMaterialWrapper";
import { Module } from "../../types";

const StudyMaterialPage = lazy(() => import("./pages/StudyMaterialPage"));

export default {
  route: {
    path: "/study-material",
    element: <StudyMaterialWrapper />,
    children: [
      {
        path: "/study-material",
        index: true,
        element: <StudyMaterialPage />,
      },
      {
        path: "/study-material/:studyGroupId",
        element: <StudyMaterialPage />,
      },
    ],
  },
  allowed: (userView) => !!userView.modules.studyMaterial,
  name: <FormattedMessage id="module.study-material.name" defaultMessage="Study materials" />,
  icon: <Archive />,
} satisfies Module;
