import { IsEnum, IsUUID } from "class-validator";
import { Transform } from "class-transformer";

import { transformLocalized, transformMoment, IsMoment } from "@namedicinu/internal-types";
import { IsLocalizedString, IsLocalizedFile } from "../helpers/decorators";
import { Localized } from "@namedicinu/internal-types";
import { type AssetType, assetTypes } from "../types";

type File = {
  contentType: string;
  fileName: string;
  fileSize: number;
  fileId: string;
};

export class WebAsset {
  @IsUUID()
  webAssetId: string;
  @IsEnum(Object.keys(assetTypes))
  type: AssetType;
  @Transform(transformLocalized)
  @IsLocalizedString()
  title: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedFile()
  file: Localized<File>;
  @Transform(transformMoment)
  @IsMoment()
  createdAt: moment.Moment;
  @Transform(transformMoment)
  @IsMoment()
  updatedAt: moment.Moment;
}
