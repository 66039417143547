import {
  IsArray,
  IsBoolean,
  IsEmail,
  IsEnum,
  IsLowercase,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from "class-validator";
import { Transform, Type } from "class-transformer";
import type { Moment } from "moment";
import { getCountryDataList, getCountryData, type TCountryCode } from "countries-list";

import type { LocaleType, RoleType, TractType } from "../types";
import { locales, roles, tracts } from "../types";
import { transformMoment, transformMomentDate, transformMomentDateEnd } from "../helpers/transforms";
import { IsMoment, IsMomentDate, IsOptionalString } from "../helpers/decorators";

export const countryCodes = getCountryDataList().map((country) => country.iso2);

export class User {
  @IsEmail()
  @IsLowercase()
  email: string;
  @IsString()
  firstName: string;
  @IsString()
  lastName: string;
  @IsEnum(roles)
  role: RoleType;

  // contact
  @IsOptional()
  @IsString()
  address: string;
  @IsOptional()
  @IsString()
  city: string;
  @IsOptional()
  @IsString()
  zipCode: string;
  @IsEnum(countryCodes)
  country: TCountryCode;
  @IsOptional()
  @IsString()
  about?: string;
  @IsOptionalString()
  @IsEmail()
  contactEmail?: string;
  @IsOptional()
  @IsArray()
  @IsString({ each: true })
  contactGroups?: string[];

  // reference
  @IsOptional()
  @IsString()
  reference?: string;
  @IsOptional()
  @IsString()
  referenceUserId?: string;
  @IsOptional()
  @IsBoolean()
  referenceUserClaimed?: boolean;

  // settings
  @IsOptionalString()
  @IsEnum([...locales])
  locale?: LocaleType;
  @IsOptional()
  @IsString()
  timezone?: string;

  // meta
  @Transform(transformMoment)
  @IsMoment()
  registeredAt: Moment;
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => ActiveContentAccess)
  activeContentAccess: Array<ActiveContentAccess>;
  @IsOptional()
  @IsString()
  setupSecret?: string;

  hasExactRole(role: RoleType): boolean {
    return this.role == role;
  }

  isAdmin(): boolean {
    return this.hasExactRole("admin");
  }

  isLector(): boolean {
    return this.hasExactRole("lector") || this.hasExactRole("admin");
  }

  isContentManager(): boolean {
    return this.hasExactRole("content-manager") || this.hasExactRole("admin");
  }

  isUser(): boolean {
    return (
      this.hasExactRole("user") ||
      this.hasExactRole("lector") ||
      this.hasExactRole("content-manager") ||
      this.hasExactRole("admin")
    );
  }

  isRole(role: RoleType): boolean {
    if (role == "admin") return this.isAdmin();
    if (role == "lector") return this.isLector();
    if (role == "content-manager") return this.isContentManager();
    if (role == "user") return this.isUser();
    if (role == "registered") return this.role == "registered";
    if (role == "deactivated") return this.role == "deactivated";
    return false;
  }

  hasContentAccess(
    categoryId: string | undefined,
    topicId: string | undefined,
    tract: TractType,
    courseRegistrationId?: string,
  ): boolean {
    if (courseRegistrationId) {
      return this.activeContentAccess.some(
        (access) =>
          (!categoryId || access.categoryId === categoryId) &&
          (!topicId || access.topicId === topicId) &&
          (access.tract === tract || access.tract === "ALL") &&
          access.courseRegistrationId === courseRegistrationId,
      );
    } else {
      if (this.isAdmin()) {
        return true;
      }
      if (tract == "quiz" && this.isLector()) {
        return true;
      }
      if (tract == "studyMaterial" && this.isLector()) {
        return true;
      }
      if (tract == "video" && this.isContentManager()) {
        return true;
      }
      if (this.isUser()) {
        return this.activeContentAccess.some(
          (access) =>
            (!categoryId || access.categoryId === categoryId) &&
            (!topicId || access.topicId === topicId) &&
            (access.tract === tract || access.tract === "ALL"),
        );
      }
    }
    return false;
  }

  public get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public get countryName(): string {
    return getCountryData(this.country).native;
  }

  public get gender(): "female" | "male" {
    // TODO move to a propper user property
    return this.firstName.endsWith("a") ? "female" : "male";
  }

  getLocalizedDomain(): string {
    const locale = this.getLocale();
    if (locale == "sk") return "namedicinu.sk";
    if (locale == "cs") return "namedicinu.cz";
    if (locale == "en") return "premedicalcourse.com";
    return "namedicinu.sk";
  }

  getLocalizedHost(login?: boolean): string {
    return `https://${login ? "login." : ""}${this.getLocalizedDomain()}`;
  }

  getLocale(): LocaleType {
    if (this.locale) {
      return this.locale;
    } else {
      const countryData = getCountryData(this.country);
      if ((locales as readonly string[]).includes(countryData.languages[0] as string)) {
        return countryData.languages[0] as LocaleType;
      }
      return "sk";
    }
  }

  getTimezone(): string {
    if (this.timezone) {
      return this.timezone;
    } else {
      return "Europe/Bratislava";
    }
  }
}

export class ContentAccess {
  @IsUUID()
  contentAccessId: string;
  @IsOptional()
  @IsString()
  courseRegistrationId?: string;
  @IsString()
  categoryId: string;
  @IsString()
  topicId: string;
  @IsEnum(tracts)
  tract: TractType;
  @IsOptional()
  @IsString()
  studyGroupId?: string;
  @Transform(transformMomentDate)
  @IsMomentDate()
  since: Moment;
  @Transform(transformMomentDateEnd)
  @IsMomentDate()
  until: Moment;
}

export class ActiveContentAccess {
  @IsString()
  categoryId: string;
  @IsString()
  topicId: string;
  @IsEnum(tracts)
  tract: TractType;
  @IsOptional()
  @IsString()
  courseRegistrationId?: string;
  @IsOptional()
  @IsString()
  studyGroupId?: string;
}
