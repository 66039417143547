import "reflect-metadata";

import ReactDOM from 'react-dom/client';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { initializeAuth, browserLocalPersistence, browserPopupRedirectResolver, connectAuthEmulator } from "firebase/auth";
// import { registerSW } from 'virtual:pwa-register';
import * as Sentry from '@sentry/react';

import { AppProvider } from './contexts/AppContext';
import { splitAsHostPort } from "./helpers/utils";
import ApiClient from './clients/apiClient';
import App from './App';
import AppConfigClient from "./clients/appConfigClient";
import AppError from './components/AppError';
import LocalDatabase from './clients/localDatabase';
import QuizClient from './clients/quizClient';
import VideoClient from "./clients/videoClient";
import SchemaClient from './clients/schemaClient';
import StorageClient from "./clients/storageClient";
import StatsClient from "./clients/statsClient";

import './index.scss';
import WebAssetTitleClient from "./clients/webAssetTitleClient";

const firebaseConfig = {
  apiKey: "AIzaSyDdpW-ys6tAfDaEGS784WvbSQj1NUrce1g",
  authDomain: "namedicinu.firebaseapp.com",
  projectId: "namedicinu",
  messagingSenderId: "613959884593",
  appId: "1:613959884593:web:caecba62ad2afdb9165a10"
};

const app = initializeApp(firebaseConfig);
const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
});
const analytics = getAnalytics(app);
const storageSource = getStorage(app, "gs://namedicinu_source_europe-west1");
const storagePublic = getStorage(app, "gs://namedicinu_public");

if(import.meta.env.VITE_FIREBASE_AUTH_URL) {
  connectAuthEmulator(auth, import.meta.env.VITE_FIREBASE_AUTH_URL, {
    disableWarnings: true,
  });
}
if(import.meta.env.VITE_FIREBASE_STORAGE_URL) {
  const [host, port] = splitAsHostPort(import.meta.env.VITE_FIREBASE_STORAGE_URL);
  connectStorageEmulator(storageSource, host, port);
  connectStorageEmulator(storagePublic, host, port);
}

if(!import.meta.env.DEV) {
  Sentry.init({
    dsn: 'https://02076df4faf74bc7438085e076e22b4f@o4504366448246784.ingest.us.sentry.io/4507233505574912',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

const localDatabase = new LocalDatabase();
const apiClient = new ApiClient();
const storageClient = new StorageClient(storageSource, storagePublic);
const statsClient = new StatsClient(apiClient, localDatabase);
const quizClient = new QuizClient(apiClient, storageClient, statsClient, localDatabase);
const videoClient = new VideoClient(apiClient, localDatabase);
const schemaClient = new SchemaClient();
const appConfigClient = new AppConfigClient();
const webAssetTitleClient = new WebAssetTitleClient(apiClient);

//TODO ? do we do <React.StrictMode>

ReactDOM.createRoot(document.getElementById('root')!).render(
  <AppError>
    <AppProvider
      {...{
        auth,
        analytics,
        storageSource,
        storagePublic,
        apiClient,
        storageClient,
        localDatabase,
        quizClient,
        videoClient,
        schemaClient,
        appConfigClient,
        statsClient,
        webAssetTitleClient,
      }}
    >
      <App />
    </AppProvider>
  </AppError>,
)

// registerSW({
//   onRegistered: (r) => {
//     r && setInterval(() => {
//       r.update()
//     }, 43200000) 
//   }
// })

navigator.serviceWorker.getRegistrations().then(registrations => {
  for (const registration of registrations) {
    registration.unregister();
  } 
});