import axios, { AxiosInstance } from "axios";
import { User as AuthUser } from "firebase/auth";

import {
  CategoryWithTopics,
  Client,
  ContentAccess,
  CourseRegistration,
  CourseRegistrationState,
  CourseRegistrationWithUser,
  Email,
  IssueReport,
  JsonObject,
  LocaleType,
  PublicQuizRelease,
  QuizLogEntry,
  QuizRelease,
  QuizSelection,
  ReportState,
  RoleType,
  StudyGroup,
  StudyMaterial,
  Task,
  TractType,
  User,
  UserNotification,
  UserQuestionModel,
  UserReferenceClaim,
  UserVideoWatch,
  Video,
  VideoPublic,
  VideoPublicPlayback,
  instanceValidate,
  instancesValidate,
  plain,
} from "@namedicinu/internal-types";
import {
  WebAsset,
  WebDeployment,
  WebEntry,
  WebEntryType,
  instanceValidateWebEntry,
  instancesValidateWebEntry,
} from "@namedicinu/web-types";
import { QuizLogRequest, QuizLogSelection, VideoLogRequest } from "./types";
import { Course } from "../types";

type CallResult = {
  status: string;
  message: string;
};

export default class ApiClient {
  api: AxiosInstance;
  authUser: AuthUser | null = null;

  constructor() {
    this.api = axios.create({
      baseURL: "/api",
      headers: {
        "Content-Type": "application/json",
      },
    });
    this.api.interceptors.request.use(async (reqConfig) => {
      if (this.authUser) {
        const token = await this.authUser.getIdToken();
        reqConfig.headers["Authorization"] = `Bearer ${token}`;
      }
      return reqConfig;
    });
  }

  async connectAuth(authUser: AuthUser | null): Promise<void> {
    this.authUser = authUser;
  }

  async getContentFor(userId: string): Promise<CategoryWithTopics[]> {
    const res = await this.api.get(`/content/for/${userId}`);
    return res.data;
  }

  async getCategories(): Promise<Array<CategoryWithTopics>> {
    const res = await this.api.get("/content/category");
    return instancesValidate(CategoryWithTopics, res.data);
  }

  async getCategory(categoryId: string): Promise<CategoryWithTopics> {
    const res = await this.api.get(`/content/category/${categoryId}`);
    return instanceValidate(CategoryWithTopics, res.data);
  }

  async storeCategory(category: CategoryWithTopics): Promise<CallResult> {
    const res = await this.api.post("/content/category", plain(category));
    return res.data;
  }

  async deleteCategory(categoryId: string): Promise<CallResult> {
    const res = await this.api.delete(`/content/category/${categoryId}`);
    return res.data;
  }

  async getUserVideos(search: {
    categoryId?: string;
    topicId?: string;
    relatedVideoId?: string;
    limit?: number;
  }): Promise<Array<VideoPublic>> {
    const res = await this.api.get(`/video/my`, {
      params: search,
    });
    return instancesValidate(VideoPublic, res.data);
  }

  async getVideos(search: {
    categoryId?: string;
    topicId?: string;
    published?: boolean;
    lectureNumber?: number;
    lectorId?: string;
    search?: string;
    limit?: number;
    offset?: number;
  }): Promise<Array<Video>> {
    const res = await this.api.get("/video", {
      params: search,
    });
    return instancesValidate(Video, res.data);
  }

  async getVideo(videoId: string): Promise<Video> {
    const res = await this.api.get(`/video/${videoId}`);
    return instanceValidate(Video, res.data);
  }

  async storeVideo(video: Video): Promise<CallResult> {
    const res = await this.api.post("/video", plain(video));
    return res.data;
  }

  async publishVideo(videoId: string): Promise<CallResult> {
    const res = await this.api.post(`/video/${videoId}/publish`);
    return res.data;
  }

  async unpublishVideo(videoId: string): Promise<CallResult> {
    const res = await this.api.post(`/video/${videoId}/unpublish`);
    return res.data;
  }

  async triggerVideoPackage(videoId: string): Promise<
    CallResult & {
      taskId: string;
    }
  > {
    const res = await this.api.post(`/video/${videoId}/trigger-package`);
    return res.data;
  }

  async getVideoPlayback(videoId: string): Promise<VideoPublicPlayback> {
    const res = await this.api.get(`/video/${videoId}/playback`);
    return instanceValidate(VideoPublicPlayback, res.data);
  }

  async submitQuizLog(quizLogRequest: QuizLogRequest): Promise<CallResult> {
    const res = await this.api.post("/quiz/log", quizLogRequest);
    return res.data;
  }

  async submitVideoLog(videoLogRequest: VideoLogRequest): Promise<void> {
    await this.api.post(`/video/log`, videoLogRequest);
  }

  async getLastMistakenAnswers(): Promise<Array<QuizLogEntry>> {
    const res = await this.api.get("/quiz/last-mistaken-answers");
    return instancesValidate(QuizLogEntry, res.data);
  }

  async getQuizReleases(): Promise<Array<QuizRelease>> {
    const res = await this.api.get(`/quiz/release/`);
    return instancesValidate(QuizRelease, res.data);
  }

  async getQuizRelease(categoryId: string): Promise<PublicQuizRelease> {
    const res = await this.api.get(`/quiz/release/${categoryId}`);
    return instanceValidate(PublicQuizRelease, res.data);
  }

  async triggerQuizRelease(categoryId: string): Promise<
    CallResult & {
      releaseId: string;
      taskId: string;
    }
  > {
    const res = await this.api.post(`/quiz/release/${categoryId}/trigger-release`);
    return res.data;
  }

  async finalizeQuizRelease(releaseId: string): Promise<CallResult> {
    const res = await this.api.post(`/quiz/release/finalize-release`, { releaseId });
    return res.data;
  }

  async getUserQuestionModel(categoryId: string, topicId: string): Promise<UserQuestionModel> {
    const res = await this.api.get(`/quiz/user-question-model/${categoryId}/${topicId}`);
    return instanceValidate(UserQuestionModel, res.data);
  }

  async createQuizLink(
    selection: QuizLogSelection,
    seed: string,
  ): Promise<
    CallResult & {
      linkId: string;
    }
  > {
    const res = await this.api.post(`/quiz/link`, { selection, seed });
    return res.data;
  }

  async getQuizLinkData(quizLinkId: string): Promise<{
    selection: QuizSelection;
    seed: string;
  }> {
    const res = await this.api.get(`/quiz/link/${quizLinkId}`);
    return {
      selection: await instanceValidate(QuizSelection, res.data.selection),
      seed: res.data.seed,
    };
  }

  async getUsers(search: {
    active?: boolean;
    role?: RoleType;
    categoryId?: string;
    topicId?: string;
    tract?: TractType;
    search?: string;
    limit?: number;
    offset?: number;
  }): Promise<Array<User>> {
    const res = await this.api.get("/user", {
      params: search,
    });
    return instancesValidate(User, res.data);
  }

  async getUser(userId: string): Promise<User> {
    const res = await this.api.get(`/user/${userId}`);
    return instanceValidate(User, res.data);
  }

  async getUserInitMe(): Promise<{
    user: User;
    notifications: Array<UserNotification>;
    courseRegistrations: Array<CourseRegistration>;
    content: Array<CategoryWithTopics>;
    studyGroups: Array<StudyGroup>;
  }> {
    const res = await this.api.get(`/user/initMe`);
    return {
      user: await instanceValidate(User, res.data.user),
      notifications: await instancesValidate(UserNotification, res.data.notifications),
      courseRegistrations: await instancesValidate(CourseRegistration, res.data.courseRegistrations),
      content: await instancesValidate(CategoryWithTopics, res.data.content),
      studyGroups: await instancesValidate(StudyGroup, res.data.studyGroups),
    };
  }

  async storeUser(user: User): Promise<CallResult> {
    const res = await this.api.post("/user", plain(user));
    return res.data;
  }

  async getUserContentAccess(userId: string): Promise<Array<ContentAccess>> {
    const res = await this.api.get(`/user/${userId}/content-access`);
    return instancesValidate(ContentAccess, res.data);
  }

  async storeUserContentAccess(userId: string, contentAccess: Array<ContentAccess>): Promise<CallResult> {
    const res = await this.api.post(`/user/${userId}/content-access`, contentAccess.map(plain));
    return res.data;
  }

  async getUserCourseRegistrations(userId: string): Promise<Array<CourseRegistration>> {
    const res = await this.api.get(`/user/${userId}/course-registration`);
    return instancesValidate(CourseRegistration, res.data);
  }

  async getUserMeCourseRegistrations(): Promise<Array<CourseRegistration>> {
    const res = await this.api.get(`/user/me/course-registration`);
    return instancesValidate(CourseRegistration, res.data);
  }

  async storeUserCourseRegistration(userId: string, courseRegistration: CourseRegistration): Promise<CallResult> {
    const res = await this.api.post(`/user/${userId}/course-registration`, plain(courseRegistration));
    return res.data;
  }

  async changeCourseRegistrationState(
    userId: string,
    courseRegistrationId: string,
    state: CourseRegistrationState,
  ): Promise<CallResult> {
    const res = await this.api.post(`/user/${userId}/course-registration/${courseRegistrationId}/state`, { state });
    return res.data;
  }

  async getCourses(): Promise<Array<Course>> {
    const res = await this.api.get("/register/course");
    return res.data;
  }

  async registerToCourse(course: {
    productId: string;
    termId: string;
    preferredTime: string;
    preferredDay: string;
    faculty: string;
    variation: string;
    subjects: string[];
    paymentFrequency: string;
  }): Promise<CallResult> {
    const res = await this.api.post(`/register/course`, course);
    return res.data;
  }

  async getCourseRegistrations(search: {
    state?: CourseRegistrationState;
    userId?: string;
    productId?: string;
    termId?: string;
    search?: string;
    limit?: number;
    offset?: number;
  }): Promise<Array<CourseRegistrationWithUser>> {
    const res = await this.api.get(`/course-registration`, {
      params: search,
    });
    return instancesValidate(CourseRegistrationWithUser, res.data);
  }

  async getLastTasks(): Promise<Array<Task>> {
    const res = await this.api.get("/task");
    return instancesValidate(Task, res.data);
  }

  async getTaskStatus(taskId: string): Promise<Task> {
    const res = await this.api.get(`/task/${taskId}`);
    return instanceValidate(Task, res.data);
  }

  async getLastEmails(search: {
    from?: string;
    to?: string;
    state?: string;
    search?: string;
    scheduled?: boolean;
    limit?: number;
    offset?: number;
  }): Promise<Array<Email>> {
    const res = await this.api.get("/email", {
      params: search,
    });
    return instancesValidate(Email, res.data);
  }

  async getWebEntries<T extends WebEntry>(type: WebEntryType): Promise<Array<T>> {
    const res = await this.api.get(`/web/entry/${type}`);
    return instancesValidateWebEntry(type, res.data);
  }

  async getWebEntry<T extends WebEntry>(type: WebEntryType, id: string): Promise<T> {
    const res = await this.api.get(`/web/entry/${type}/${id}`);
    return instanceValidateWebEntry(type, res.data);
  }

  async storeWebEntry(type: WebEntryType, entry: WebEntry): Promise<CallResult> {
    const res = await this.api.post(`/web/entry/${type}`, plain(entry));
    return res.data;
  }

  async deleteWebEntry(type: WebEntryType, id: string): Promise<CallResult> {
    const res = await this.api.delete(`/web/entry/${type}/${id}`);
    return res.data;
  }

  async getWebAssets(): Promise<Array<WebAsset>> {
    const res = await this.api.get(`/web/asset`);
    return instancesValidate(WebAsset, res.data);
  }

  async getWebAsset(assetId: string): Promise<WebAsset> {
    const res = await this.api.get(`/web/asset/${assetId}`);
    return instanceValidate(WebAsset, res.data);
  }

  async uploadWebAssetFile(fileId: string, file: Blob): Promise<CallResult> {
    const res = await this.api.post(`/web/asset-file/${fileId}`, file, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });
    return res.data;
  }

  async getWebAssetFile(fileId: string): Promise<ArrayBuffer> {
    const res = await this.api.get(`/web/asset-file/${fileId}`, { responseType: "arraybuffer" });
    return res.data;
  }

  async storeWebAsset(asset: WebAsset): Promise<CallResult> {
    const res = await this.api.post(`/web/asset`, plain(asset));
    return res.data;
  }

  async deleteWebAsset(assetId: string): Promise<CallResult> {
    const res = await this.api.delete(`/web/asset/${assetId}`);
    return res.data;
  }

  async triggerWebAssetProcessing(assetId: string): Promise<
    CallResult & {
      taskId: string;
    }
  > {
    const res = await this.api.post(`/web/asset/${assetId}/trigger-processing`);
    return res.data;
  }

  async getWebDeployments(): Promise<Array<WebDeployment>> {
    const res = await this.api.get(`/web/deploy`);
    return instancesValidate(WebDeployment, res.data);
  }

  async triggerWebDeployment(
    locale: string,
    test: boolean,
  ): Promise<
    CallResult & {
      taskId: string;
    }
  > {
    const res = await this.api.post(`/web/deploy/trigger-deploy`, { locale, test });
    return res.data;
  }

  async getStats(
    modelId: string,
    dimensions: string[],
    selection: { [key: string]: any | any[] },
    queryMetrics: string[],
  ): Promise<JsonObject[]> {
    const modelNames: Record<string, string> = {
      quizSession: "quiz-session",
      quizQuestion: "quiz-question",
      quizAnswer: "quiz-answer",
      videoSession: "video-session",
      videoView: "video-view",
    };
    const modelName = modelNames[modelId];
    if (!modelName) throw new Error(`Unknown modelId->modelName: ${modelId}`);
    const res = await this.api.post(`/stats/${modelName}`, {
      dimensions,
      selection,
      queryMetrics,
    });
    return res.data;
  }

  async getClient(clientId: string): Promise<Client> {
    const res = await this.api.get(`/client/${clientId}`);
    return instanceValidate(Client, res.data);
  }

  async getLectorStudents(): Promise<Array<User>> {
    const res = await this.api.get(`/lector/students`);
    return instancesValidate(User, res.data);
  }

  async getAdminStudyGroups(search: {
    productId?: string;
    termId?: string;
    categoryId?: string;
    topicId?: string;
    lector?: string;
    search?: string;
    limit?: number;
    offset?: number;
  }): Promise<Array<StudyGroup>> {
    const res = await this.api.get(`/study-group`, {
      params: search,
    });
    return instancesValidate(StudyGroup, res.data);
  }

  async getAdminStudyGroupAssignment(search: {
    productId?: string;
    termId?: string;
    categoryId?: string;
    topicId?: string;
  }): Promise<{
    studyGroups: StudyGroup[];
    users: {
      userId: string;
      userName: string;
      assignments: {
        contentAccessId: string | null;
        has: boolean;
      }[];
    }[];
  }> {
    const res = await this.api.get(`/study-group/assignment`, {
      params: search,
    });
    return {
      studyGroups: await instancesValidate(StudyGroup, res.data.studyGroups),
      users: res.data.users,
    };
  }

  async adminStudyGroupAssign(userId: string, contentAccessId: string, studyGroupId: string): Promise<CallResult> {
    const res = await this.api.post(`/study-group/assignment`, { userId, contentAccessId, studyGroupId });
    return res.data;
  }

  async storeStudyGroup(studyGroup: StudyGroup): Promise<CallResult> {
    const res = await this.api.post(`/study-group`, plain(studyGroup));
    return res.data;
  }

  async deleteStudyGroup(studyGroupId: string): Promise<CallResult> {
    const res = await this.api.delete(`/study-group/${studyGroupId}`);
    return res.data;
  }

  async getLectorStudyGroups(): Promise<Array<StudyGroup>> {
    const res = await this.api.get(`/lector/study-group`);
    return instancesValidate(StudyGroup, res.data);
  }

  async getStudyGroupStudyMaterials(studyGroupId: string): Promise<Array<StudyMaterial>> {
    const res = await this.api.get(`/study-group/${studyGroupId}/study-material`);
    return instancesValidate(StudyMaterial, res.data);
  }

  async storeStudyGroupStudyMaterial(studyGroupId: string, studyMaterial: StudyMaterial): Promise<CallResult> {
    const res = await this.api.post(`/study-group/${studyGroupId}/study-material`, plain(studyMaterial));
    return res.data;
  }

  async deleteStudyGroupStudyMaterial(studyGroupId: string, studyMaterialId: string): Promise<CallResult> {
    const res = await this.api.delete(`/study-group/${studyGroupId}/study-material/${studyMaterialId}`);
    return res.data;
  }

  async getLectorVideoWatchTable(
    categoryId: string,
    topicId: string,
  ): Promise<{
    students: User[];
    videos: {
      video: Video;
      videoWatches: UserVideoWatch[];
    }[];
  }> {
    const res = await this.api.get(`/lector/video-watch/${categoryId}/${topicId}`);
    const videos: {
      video: Video;
      videoWatches: UserVideoWatch[];
    }[] = [];
    for (const item of res.data.videos) {
      videos.push({
        video: await instanceValidate(Video, item.video),
        videoWatches: await instancesValidate(UserVideoWatch, item.videoWatches),
      });
    }
    return {
      students: await instancesValidate(User, res.data.students),
      videos,
    };
  }

  async updateVideoAttended(userId: string, videoId: string, attended: boolean): Promise<void> {
    await this.api.post(`/lector/video-watch/${userId}/${videoId}/attended`, { attended });
  }

  async getStudyGroups(): Promise<Array<StudyGroup>> {
    const res = await this.api.get(`/user-study/study-group`);
    return instancesValidate(StudyGroup, res.data);
  }

  async getLectors(): Promise<Array<User>> {
    const res = await this.api.get(`/content-manager/lectors`);
    return instancesValidate(User, res.data);
  }

  async getAdminOverview(): Promise<{
    currentStudents: number;
    allStudents: number;
    lectors: number;
    newCourseRegistrations: number;
    courseRegistrations: number;
  }> {
    const res = await this.api.get(`/admin/overview`);
    return res.data;
  }

  async getAdminStatsUserAbuse(): Promise<
    Array<{
      email: string;
      score: number;
      clients: number;
      totalActivity: number;
    }>
  > {
    const res = await this.api.get(`/admin/stats/user-abuse`);
    return res.data;
  }

  async getUserMeReferenceClaims(): Promise<Array<UserReferenceClaim>> {
    const res = await this.api.get("/user/me/reference-claim");
    return instancesValidate(UserReferenceClaim, res.data);
  }

  async getUserReferenceClaims(): Promise<Array<UserReferenceClaim>> {
    const res = await this.api.get(`/user/reference-claim`);
    return instancesValidate(UserReferenceClaim, res.data);
  }

  async changeUserReferenceClaimed(reference: string, claimed: boolean): Promise<CallResult> {
    const res = await this.api.post(`/user/reference-claim/${reference}/claimed`, { claimed });
    return res.data;
  }

  async getIssueReports(search: {
    state?: string;
    targetType?: string;
    reporterId?: string;
    offset?: number;
    limit?: number;
  }): Promise<Array<IssueReport>> {
    const res = await this.api.get(`/issue-report`, {
      params: search,
    });
    return instancesValidate(IssueReport, res.data);
  }

  async getMyIssueReports(): Promise<Array<IssueReport>> {
    const res = await this.api.get(`/issue-report/my`);
    return instancesValidate(IssueReport, res.data);
  }

  async makeIssueReport(
    issueReport: {
      message: string;
    } & (
      | {
          targetType: "global";
        }
      | {
          targetType: "quiz";
          target: {
            categoryId: string;
            topicId: string;
            questionNumber: number;
          };
        }
      | {
          targetType: "video";
          target: {
            categoryId: string;
            topicId: string;
            videoId: string;
          };
        }
    ),
  ): Promise<CallResult> {
    const res = await this.api.post(`/issue-report`, issueReport);
    return res.data;
  }

  async updateIssueReportState(issueReportId: string, state: ReportState): Promise<CallResult> {
    const res = await this.api.post(`/issue-report/${issueReportId}/state`, { state });
    return res.data;
  }

  async getNotifications(): Promise<Array<UserNotification>> {
    const res = await this.api.get("/notification");
    return instancesValidate(UserNotification, res.data);
  }

  async acknowledgeNotification(userNotificationId: string): Promise<CallResult> {
    const res = await this.api.post(`/notification/${userNotificationId}/acknowledge`);
    return res.data;
  }

  async acknowledgeAllNotifications(): Promise<CallResult> {
    const res = await this.api.post(`/notification/acknowledge`);
    return res.data;
  }

  async userSetupAuthorize(userId: string): Promise<
    CallResult & {
      link: string;
    }
  > {
    const res = await this.api.post(`/user-setup/authorize`, {
      userId,
    });
    return res.data;
  }

  async userSetupInvalidate(userId: string): Promise<CallResult> {
    const res = await this.api.post(`/user-setup/invalidate`, {
      userId,
    });
    return res.data;
  }

  async userSetupStart(token: string): Promise<
    CallResult & {
      link: string;
    }
  > {
    const res = await this.api.post(`/user-setup/start`, {
      token,
    });
    return res.data;
  }

  async generateEmail(options: {
    template: string;
    locale: LocaleType;
    userId?: string;
    courseRegistrationId?: string;
    month?: string;
    params?: JsonObject;
  }): Promise<
    CallResult & {
      subject: string;
      text: string;
      html: string;
    }
  > {
    const res = await this.api.post(`/generator/email`, options);
    return res.data;
  }

  async generateAttachment(options: {
    template: string;
    locale: LocaleType;
    userId?: string;
    courseRegistrationId?: string;
    month?: string;
    params?: JsonObject;
  }): Promise<
    CallResult & {
      html: string;
    }
  > {
    const res = await this.api.post(`/generator/attachment`, options);
    return res.data;
  }

  async printAttachment(options: {
    template: string;
    locale: LocaleType;
    userId?: string;
    courseRegistrationId?: string;
    month?: string;
    params?: JsonObject;
  }): Promise<
    CallResult & {
      link: string;
      newTaskId: string | null;
    }
  > {
    const res = await this.api.post(`/generator/attachment/print`, options);
    return res.data;
  }
}
